<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
            
        <div class="va-card">
          <div class="row">
            <div class="col-lg-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <h4 class="menu_header">Staff</h4>
                  <div class="">
                    <router-link :to="{ name: 'createstaff'}">
                      <button class="btn btn-default custom-orange">
                        <i class="fas fa-user-plus"></i> New Staff
                      </button>
                    </router-link>
                  </div>
                </div>
                <div class="search_section">
                  <div class="row">
                    <div class="col-md-6">
                      <input type="text" v-model="search" @keyup="searchStaffs()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..."   class="form-control" >
                          
                    </div>
                    <div class="col-md-2 col-sm-12">
                      <div class="form-group has-search">
                        <button class="btn btn-orange search">Search</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
            </div>
              
            <div class="col-lg-12">
              <div class="dash_card_body">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="table_section">
                      <table class="table  table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col" width="30%">Store</th>
                            <th scope="col">Role</th>
                            <th scope="col">Status</th>
                            <th scope="col" width="10%">Created By</th>
                            <th scope="col">Created On</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody v-if="!loading">
                          <tr v-for="staff in staffs" :key="staff.id">
                            <td>
                              <router-link :to="{ name: 'updatestaff', params: { id:staff.id }}">
                                {{staff.name}}
                              </router-link>
                            </td>
                            <td><span>{{staff.email}}</span></td>
                            <td><span>{{staff.phone}}</span></td>
                            <td>
                              <div v-if="staff.stores.length == 0">
                                N/A
                              </div>
                              <div v-else>
                                <span v-for="(store, sindex) in staff.stores" :key="store.id">
                                  {{store.branch_name}}
                                  <span v-if="sindex + 1 < staff.stores.length "> , </span>
                                </span>
                            
                              </div>

                            </td>
                            <td><span>{{staff.role_name}}</span></td>
                            <td>
                              <div class = "btn-group">
                                <button class="btn custom_btn btn-sm dropdown-toggle'" type="button"  >
                                  <span v-if="staff.active == 1">
                                    <i class='fa fa-check' style="color:green;"></i> 
                                  </span>
                                  <span v-else-if="staff.active == 0">
                                    <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                                  </span>
                                </button>
                                <button v-if="user.role.id == 1 || user.role.id == 2" type = "button" class = "btn btn-sm custom_btn btn-default dropdown-toggle" data-toggle = "dropdown" :id="'dropdownButton'+staff.id" >
                                  <span class = "caret"></span>
                                  <span class = "sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu" :aria-labelledby="'dropdownButton'+staff.id">
                                  <a class="dropdown-item" @click="activateUser(staff.id,1)" href="#">Activate</a>
                                  <a class="dropdown-item" @click="activateUser(staff.id,0)" href="#">Block</a>
                                </div>
                              </div>

                            </td>
                            <td><span>{{staff.created_by}}</span>
                            </td>
                            <td>
                              {{formatDate(staff.created_at)}}
                            </td>
                            <td>
                              <button class="btn btn-success btn-sm" @click="sendPasswordResetLink(staff.id,staff.name)"><span v-if="sending == user.id "><i class="fa fa-spinner fa-spin"></i></span> Reset Password</button>
                            </td>
                            <!-- <td>
                              <button class="btn btn-danger btn-sm" @click="deleteStaff(staff.id)"><span v-if="spinner == staff.id"><i class='fa fa-spinner fa-spin '></i></span>Delete</button>
                            </td> -->
                          </tr>

                        </tbody>
                        <tfoot v-if="!loading">
                          <tr>
                            <td colspan="8">
                              <div id="pagination" v-if="totalPages > 1">
                                <ul class="showItems">
                                  <li>Show Items:
                                    <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                      <option :value="10">10</option>
                                      <option :value="20">20</option>
                                      <option :value="30">30</option>
                                      <option :value="40">40</option>
                                      <option :value="50">50</option>
                                    </select>
                                  </li>
                            
                                </ul>
                                <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />

                              </div>
                          
                            </td>
                          </tr>
                        </tfoot>
                      </table>

                    </div>
                    <div v-if="loading" class="loading-cover">
                      <div class="loader"> </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins'
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import Pagination from '@/pages/pagination/pagination.vue';
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
    components: {
        Pagination,
    },
    data() {
        return {
            staffs: [],
            loading: false,
            page: 1,
            totalPages: 0,
            totalRecords: 0,
            recordsPerPage: 10,
            enterpageno: '',
            debounce: null,
            search:'',
            spinner: null,
            sending: null
        }
    },
    created: async function () {
        this.getStaff();
    },
    methods: {
      searchStaffs() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.getStaff();
            }, 1000)
        },
        sendPasswordResetLink(userId,name){
          this.sending = userId;
          const url = `${this.dashBaseUrl}/send-link`;
          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
          };
          const baseurl = window.location.origin +"/";
          const payload = {
            'user_id' : userId,
            'merchant_id': this.merchantID,
            'base_url': baseurl
          };
          this.axios.post(url,payload,config).then((response) => {
                if (response.status === 200) {
                  toast.success('link has been sent to user '+ name)   
                }
            }).catch((err) => {
                console.log('error', err);
            }).finally(()=> this.sending = null);
        },
        async getStaff() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            let url = `${this.dashBaseUrl}/staffs`;
            url = new URL(url);
            url.searchParams.set('page', this.page);
            url.searchParams.set('size', this.recordsPerPage); 
            url.searchParams.set('merchant_id',this.merchantID)
            if(this.search !==''){
              url.searchParams.set('search', this.search);
            }
            this.loading = true;
            this.axios.get(url,config).then((response) => {
                if (response.status === 200) {
                    this.loading = false;
                    this.staffs = response.data.data.data;
                    this.totalPages = Math.ceil(response.data.data.total / this.recordsPerPage)
                    this.totalRecords = response.data.data.total   
                }
            }).catch((err) => {
                console.log('error', err);
            })

        },
        async activateUser(id,status){
          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
          };
          const url = `${this.dashBaseUrl}/activate-user/${id}`
          const payload = {
            'status': status
          }
          const res = await this.axios.post(url,payload,config)
          if(res.status == 200){
            this.getStaff()
          }
        },
        async deleteStaff(id) {
          this.spinner = id;
          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/delete-staff/${id}`;
            this.axios.post(url,{},config).then((response)=>{
                if(response.status === 200){
                   this.getStaff();
                    toast.success('staff deleted successfully');
                }
            }).catch((error)=>{
                if(error.response.status === 400){
                  // 
                }
            }).finally(()=> this.spinner = false);
          // 

        },
        onPageChange(page) {
            this.page = page
            this.getStaff();
        },
        onChangeRecordsPerPage() {
            this.getStaff();
            // ds
        },
    },
    mixins: [ReusableFunctionMixins,ReusableDataMixins]
}
</script>
